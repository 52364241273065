<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    
    <b-row class="match-height" >
      
      <b-col
        md="4"
        @click="openPage('hand-sanitizer-buildings')"
        v-if="checkPermission('custom-forms', 'Update Item')"
      >
        <statistic-card-vertical
          icon="HomeIcon"
          statistic="Building Settings"
          color="info"
          
        />
      </b-col>

      <b-col
        md="4"
        @click="openPage('custom-form-validate-settings')"
      >
        <statistic-card-vertical
          icon="SettingsIcon"
          statistic="Validation Settings"
          color="warning"
          
        />
      </b-col>

      <b-col
        md="4"
        @click="openPage('custom-form-site-validate-settings')"
      >
        <statistic-card-vertical
          icon="SettingsIcon"
          statistic="Site Validation Settings"
          color="success"
          
        />
      </b-col>
      
    </b-row>

      
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BForm,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BFormFile,BFormGroup, BFormCheckbox,BCardBody,BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    vSelect,
    BAlert,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    StatisticCardVertical,
    BBreadcrumb
  },
  data() {
    return {
        webUrl: process.env.VUE_APP_SERVER_URL,
        showDismissibleAlert:false,
        
    }
  },
  methods : {
    openPage(page){
      this.$router.push({ name: page});
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:null,
        text:'Form Settings',
        active:true
      }];
      return item;
    }
  },
  mounted(){
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
